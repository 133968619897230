import {makeStyles, tokens, Spinner} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import PageHeader from '../../components/PageHeader';
import {useAppSelector} from '../../store/hooks';
import {deviceReachable, fetchTags, getDeviceInfos, getScenarioInfos} from '../../fetcher';
import {useEffect, useState} from 'react';
import {AoAScenarioInfo, DeviceInfo} from '../../types';
import ResourceGroupTree from '../../components/ResourceGroupTree';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {DeviceTable} from './DevicesTable';
import ScenarioPanel from './ScenarioPanel';
import PageContent from '../../components/PageContent';
import NoValidOrganization from '../../components/NoValidOrganization';

const useStyles = makeStyles({
  spinner: {
    paddingTop: tokens.spacingVerticalXXL
  }
});

const Reporting = () => {
  const styles = useStyles();
  const {organization, loaded} = usePopulatedTopbarValues();
  const openTelemetry = useOpenTelemetry();
  const [devices, setDevices] = useState<DeviceInfo[] | undefined>(undefined);
  const [selectedSerial, setSelectedSerial] = useState<string>('');
  const [panelOpen, setPanelOpen] = useState<boolean>(false);
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [tagOptions, setTagOptions] = useState<string[] | undefined>(undefined);
  // TODO: Consider distinguishing no scenarios from no compatible scenarios.
  const [scenarios, setScenarios] = useState<AoAScenarioInfo[] | undefined>(undefined);

  useEffect(() => {
    setTagOptions(undefined);
    setDevices(undefined);
    if (!organization?.arn) {
      return;
    }
    const tags = fetchTags({organizationArn: organization.arn}, openTelemetry);
    const devices = getDeviceInfos({organizationArn: organization.arn}, openTelemetry);
    tags.then(setTagOptions);
    devices
      .then(devices => {
        setDevices(devices);
        return devices;
      })
      .then(devices => {
        devices?.forEach(device => {
          deviceReachable(organization.arn, device.serial, openTelemetry).then(reachable => {
            setDevices(devices =>
              devices?.map(d => {
                if (d.serial === device.serial) {
                  return {...d, reachable};
                }
                return d;
              })
            );
          });
        });
      });
  }, [organization?.arn, openTelemetry]);

  const selectedDevice = devices?.find(d => d.serial === selectedSerial);

  useEffect(() => {
    setScenarios(undefined);
    if (
      !organization?.id ||
      !selectedDevice?.serial ||
      !selectedDevice.reachable ||
      !selectedDevice.aoaRunning
    ) {
      return;
    }
    getScenarioInfos(
      {serial: selectedDevice.serial, organizationId: organization.id},
      openTelemetry
    ).then(scenarios => setScenarios(scenarios));
  }, [selectedDevice, openTelemetry, organization?.id]);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  return (
    <>
      <ResourceGroupTree />
      <PageContent data-testid="devices-tab">
        <PageHeader title={selectedResourceGroup.name} />
        {devices === undefined ? (
          <Spinner className={styles.spinner} size="large" />
        ) : (
          <>
            <DeviceTable
              devices={devices.filter(
                device =>
                  selectedResourceGroup.id === '' || device.arn.includes(selectedResourceGroup.id)
              )}
              onDeviceSelected={serial => {
                if (serial !== selectedSerial) {
                  setScenarios(undefined);
                }
                setSelectedSerial(serial);
                setPanelOpen(true);
              }}
            />
            <ScenarioPanel
              scenarios={scenarios}
              setScenarios={setScenarios}
              device={selectedDevice}
              panelOpen={panelOpen}
              onClose={() => setPanelOpen(false)}
              tagOptions={tagOptions}
              setTagOptions={setTagOptions}
            />
          </>
        )}
      </PageContent>
    </>
  );
};

export default Reporting;
